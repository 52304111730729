import HeroSection from "../HeroSection";
import AboutMe from "../AboutMe";
import MyPortfolio from "../MyPortfolio";
import ContactMe from "../ContactMe";
import Footer from "../Footer";

export default function Home(){
    return(
        <>
            <HeroSection />
            <AboutMe />
            <MyPortfolio />
            <ContactMe/>
            <Footer/>
        </>
    );
}